.poAbs {
  position: absolute;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (device-aspect-ratio: 40/71) {
  .isLandscape {
    display: none !important;
  }
  .main-container {
    margin-top: 58px !important;
    padding: 0 !important;
  }
  .header {
    ul > li {
      margin: 0px 10px 18px 40px !important;
    }
  }
  .nmb-right {
    position: absolute;
    left: auto;
    right: -311px !important;
    top: 2px !important;
  }
  .isMobile {
    display: block !important;
    //border-bottom: 1px solid #b5b4b4;
    //z-index: 99999;
    position: absolute;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eff3fe;
      border-bottom: 1px solid #b5b4b4;

      .nmb-sub-header {
        position: absolute;
        left: auto;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 97px;

        .nmb-notify {
          height: 30px;
        }

        .nmb-drop {
          height: 24px;
          width: 32px !important;
        }

        .user-icon-btn {
          width: 27px !important;
          left: -4px;
        }
      }
    }
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .mapActions {
    display: none !important;
  }
  .spinner {
    right: 8px !important;
  }
  .nav-button:hover,
  .nav-button:active,
  .nav-button:focus {
    background: yellow;
  }
  #googleMap {
    width: 100%;
    height: calc(100vh - 7.2rem);
  }
  .chart-detail-footer {
    .close-button {
      margin-left: 10px !important;

      button {
        background: black !important;
        color: white !important;
      }
    }
  }
}

@media screen and (device-aspect-ratio: 40/71) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
}

@media (max-width: 575.98px) {
  .isLandscape {
    display: none !important;
  }
  .main-container {
    //margin-top: 58px !important;
    min-height: calc(100vh - 8rem) !important;
    padding: 0 !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }
  .isMobile {
    display: block !important;
    //border-bottom: 1px solid #b5b4b4;
    z-index: 99999;
    position: fixed;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eff3fe;
      border-bottom: 1px solid #b5b4b4;

      .nmb-sub-header {
        position: absolute;
        left: auto;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 97px;

        .nmb-notify {
          height: 30px;
          display: flex;
        }

        .nmb-drop {
          height: 24px;
        }

        .user-icon-btn {
          width: 31px;
          border: none;
          float: right;
          height: 27px !important;
        }

        .map-icon-btn {
          width: 30px;
          border: none;
        }
      }
    }

    .nmb-noMargin.map-card {
      margin: 0 !important;
    }

    .logout-dropdown {
      position: absolute;
      left: auto !important;
      right: 5px !important;
    }
  }
  .chart-detail-footer {
    .close-button {
      margin-left: 10px !important;

      button {
        background: black !important;
        color: white !important;
      }
    }
  }
  .spinner {
    top: 12px !important;
    right: 8px !important;
  }
  .isHide {
    left: 0 !important;
  }
  .mat-label-card.non-dashboard-card {
    margin: 0 !important;
  }
  // .mapActions {
  //     //display: none !important;
  // }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    width: 248px !important;
    transition: all 0.2s ease-in-out !important;
  }
  .nested-menu > button > span {
    display: block !important;
  }
  .product-brand {
    border: none !important;
  }
  .Configurator,
  .Setup,
  .Management {
    display: none !important;
  }
  .user-icon-btn {
    height: 2rem !important;
  }
  .user-icon-btn::after {
    top: 12px !important;
  }
  .mat-card-content {
    height: calc(100vh - 8.5rem) !important;
  }
  #googleMap {
    width: 100%;
    height: calc(100vh - 8.7rem) !important;
  }
  .isHide {
    display: none !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .notification-container {
    top: 49px !important;
    left: 5px !important;
    width: 350px !important;
    height: calc(100vh - 6.6rem) !important;
  }
  //overview

  .highLight {
    width: 300px !important;
  }
  .work-hours1 {
    left: 50% !important;
    top: 1145px !important;
    margin: 0 !important;
  }
  .work-hours2 {
    left: 50% !important;
    top: 1692px !important;
    margin: 0 !important;
  }
  .distance1 {
    left: 50% !important;
    top: 2238px !important;
    margin: 0 !important;
  }
  .distance2 {
    left: 50% !important;
    top: 2783px !important;
    margin: 0 !important;
  }
  //    alert-details component
  .noMargin {
    margin: 0 !important;
  }
  button.noMargin.mat-button.mat-primary {
    width: 179px !important;
    left: -16px !important;
    position: relative !important;
  }

  .list-assets {
    position: absolute;
    top: 4px;
    padding: 0;

    .asset-list {
      padding: 0 15px;
    }

    // .button-group {
    //     margin: 0 0 10px 0;
    //     border: 1px solid #000 !important;
    //     position: absolute;
    //     top: auto;
    //     bottom: 0;
    //     left: 0;
    //     width: 90.2% !important;
    //     margin: 0 17px;
    // }
    .search-input {
      height: 35px;
      border: 1px solid #000 !important;
      margin: 0 !important;
      width: 85% !important;
    }
  }
}

@media only screen and (min-width: 411px) and (max-width: 731px) {
  .isLandscape {
    display: none !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .overview {
    margin-left: 0 !important;
  }
  .header {
    ul {
      li:last-child {
        margin-right: 3px !important;
      }

      li {
        margin: 0px 24px 18px 24px !important;
      }
    }
  }
  .notification-container {
    top: 49px !important;
    left: 5px !important;
    width: 401px !important;
    height: calc(100vh - 6.6rem) !important;
  }
  .work-hours1 {
    left: 50% !important;
    top: 1326px !important;
    margin: 0 !important;
  }
  .work-hours2 {
    left: 50% !important;
    top: 1963px !important;
    margin: 0 !important;
  }
  .distance1 {
    left: 50% !important;
    top: 2601px !important;
    margin: 0 !important;
  }
  .distance2 {
    left: 50% !important;
    top: 3237px !important;
    margin: 0 !important;
  }
}

@media only screen and (min-width: 411px) and (max-width: 731px) and (orientation: landscape) {
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .isLandscape {
    display: none !important;
  }
  .ag-theme-material {
    height: calc(100vh - 19rem) !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .isLandscape {
    display: none !important;
  }
  .ag-theme-material {
    height: calc(100vh - 15rem) !important;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .deskTop {
    display: none !important;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
  .ag-theme-material {
    height: calc(100vh - 15rem) !important;
  }
}

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .deskTop {
    display: none !important;
  }
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
  .ag-theme-material {
    height: calc(100vh - 15rem) !important;
  }
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .deskTop {
    display: none !important;
  }
}

// Small devices (landscape phones, 576px and up)

@media (min-width: 576px) and (max-width: 767.98px) and (orientation: portrait) {
  .isLandscape {
    display: none !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: block !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: block !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
  .isLandscape {
    display: block !important;
  }

  .main-container {
    margin-top: 0px !important;
    min-height: calc(100vh - 8rem) !important;
  }
  .deskTop,
  .nmb-hide-btns {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .isHide {
    left: 0 !important;
  }
  .isShow {
    left: 235px !important;
    top: 50px !important;
    display: none !important;
    transition: all 0.2s ease-in-out !important;
  }
  .blurStyle {
    opacity: 1 !important;
    background: transparent !important;
  }
  .deskTop {
    display: none !important;
  }
  .isLandscape {
    display: block !important;

    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 9px;
      top: auto;
      position: absolute;
      bottom: 0px;
      z-index: 99;
    }
  }

  .isMobile {
    display: none !important;

    .nmb-cstm-row {
      position: relative;
      top: 0px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mat-label-card.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 3rem) !important;
  }
  .main-container {
    margin-top: 5px !important;
    min-height: calc(100vh - 3.4rem) !important;
  }
  .scrollspy {
    position: relative;
    height: calc(100vh - 3rem) !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .isLandscape {
    display: none !important;
  }
  .navbar {
    margin-left: 0 !important;

    .search-input {
      display: none !important;
    }

    .video-analysis-icon-btn,
    .trend-analysis-icon-btn,
    .custom-select,
    .logout-button {
      display: none !important;
    }
  }

  .main-container {
    margin-top: 0px !important;
  }
  .nav-heading-card {
    display: none !important;
  }

  .isMobile {
    display: none !important;
  }
  .sidebar {
    top: 54px;
    left: 235px;
  }
  .collapsed {
    span {
      display: none;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 992px) {
  .isLandscape {
    display: none !important;
  }
  .hide {
    display: none !important;
  }
  .isMobile {
    display: none !important;
  }
  .sidebar {
    top: 54px;
    left: 235px;
  }

  // .collapsed {
  //   //span {
  //   //    display: none;
  //   //}
  // }
  .asset-panel {
    span {
      display: block !important;
      line-height: 0;
    }
  }
  .scroll-sectionS {
    .col-md-6 {
      margin: 0 !important;
      padding: 0px 4px 0 4px !important;

      .mat-card {
        height: 415px;
      }
    }
  }
  #engine-alerts-idle,
  #idling-hard-braking {
    .col-lg-6,
    .col-md-6 {
      margin: 0 !important;
      padding: 0px 4px 0 4px !important;
    }
  }
  .isHide {
    display: block !important;
  }
  .nav-heading-card {
    position: sticky !important;
  }
  .scrollspy {
    height: calc(100vh - 12.5rem) !important;
  }
  .mat-card-content {
    .col-md-2,
    .col-md-2,
    .col-sm-2,
    .isHide {
      //padding-left: 0 !important;
      padding-right: 25px !important;
    }
  }

  .tab-pane {
    .mat-card-content {
      .col-md-2,
      .col-md-2,
      .col-sm-2,
      .isHide {
        padding-left: 15px !important;
      }
    }
  }
}
