/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/dist/styles/ag-grid.scss';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'spinner';
@import 'utils';
@import 'rtl';
@import 'responsive';

//Color Scheme
$sideBarCol: aliceblue;
$primaryClr: #2a5c74;
$secondryClr: #99d1eba6;
$tertiaryClr: #f89325;
$sidebarWidth: 250px;
.form-field {
  flex: 0 0 32%;
  min-width: 300px;
}

.input-error {
  color: #f44336;
  font-size: 11px;
  padding: 3px;
}

body {
  background-color: #eff3fe !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

* {
  font-family: 'Poppins';
}

.breadcrumb {
  padding: 0.3rem 0.6rem 0.4rem;
  background-color: #f0f8ff;
  font-size: 12.5px;
  // border-radius: unset;

  .breadcrumb-item + .breadcrumb-item::before {
    color: #1a6a9f;
    content: '/';
  }

  .breadcrumb-item {
    color: #5f5b5b; // without link
    a {
      color: #1a6a9f; // with link
    }
  }
}

.card ::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  border-radius: 3px;
}

.card ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  cursor: pointer;
  border-radius: 3px;
}

.card ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 3px;
}

.card-header {
  font-weight: 500;
}

.spinner-three-bounce {
  position: fixed !important;
}

.dashboard {
  .text-muted,
  h4,
  label {
    color: $primaryClr !important;
  }

  h4 {
    font-size: 2vw !important;
  }

  .responsive-font-size {
    font-size: 1.3vw !important;
  }

  th {
    color: rgba(239, 65, 65, 0.9);
    padding: 1vw !important;
  }

  td {
    padding: 1vw !important;
    text-transform: uppercase !important;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .table-responsive-sm {
    overflow-y: scroll;
    height: 400px;
    display: block;
    width: 100%;

    th {
      text-align: center;
    }

    td {
      text-align: left;
    }
  }

  .table-responsive-lg {
    overflow-y: scroll;
    height: auto;
    display: block;
    width: 100%;

    th,
    td {
      text-align: center;
    }
  }

  .btn {
    padding: 12px 25px;
    margin-top: 12px;
  }

  .feed {
    overflow-y: scroll;
  }

  .feedBox {
    // width: auto;
    // height: auto;
    margin-top: 20px;
  }

  img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .card-header .card-img {
    border-radius: 3px;
    background-color: #999;
    padding: 10px;
    margin-top: -30px;
    margin-right: 15px;
    float: left;
    width: auto;
  }

  .card-header {
    border-bottom: none;

    .card-category {
      text-align: right;
      color: $primaryClr;
    }
  }

  .card-footer {
    background-color: inherit;
    border-top: 1px solid $primaryClr;
  }

  .flip-card {
    perspective: 1000px; // for 3d effect
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card > div {
    backface-visibility: hidden;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flip-card-front {
    transform: rotateY(0deg);
  }

  .flip-card-back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    font-size: 1.2vw;
  }

  .flip-card:hover .flip-card-front {
    transform: rotateY(-180deg);
  }

  .flip-card:hover .flip-card-back {
    transform: rotateY(0deg);
  }

  .progress-info {
    .subtitle {
      font-size: 1.2vw;
      font-weight: 600;
      line-height: 1.8vw;
    }

    .h3 {
      font-size: 2.2vw;
      font-weight: 700;
      line-height: 3vw;
      word-spacing: 60px;
    }

    .progress {
      height: 1.7vw;
    }

    .description {
      margin-top: 0.5rem;
      color: #8f9bb3;
      font-size: 0.9vw;
      font-weight: 400;
      line-height: 1.2vw;
    }
  }
}

@media print {
  .breadcrumb {
    display: none !important;
  }
}

small {
  margin-top: -10px;
  font-size: 70%;
}

.has-error {
  border: 1px solid red;
}

.has-success {
  border: 1px solid green;
}

a.al-logo {
  color: #454545;
  display: block;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
  float: left;
  outline: none !important;
}

button,
.btn,
.btn-primary,
.btn-success,
.btn-default {
  // margin-left: 1em;

  &:focus {
    outline: none !important;
  }
}

.ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 50px !important;
}

// .ag-theme-material .ag-header-cell-resize::after {
//   border-right: 1px solid $primaryClr;
//   content: " ";
//   height: 20px;
//   margin-top: 10px;
//   position: absolute;
//   text-indent: -2000px;
//   top: 0px;
//   left: 5px;
// }

.input-group .fa {
  color: $primaryClr;
}

.fa {
  margin-right: 6px;
  color: $primaryClr;
}

a {
  cursor: pointer;
}

.nav.nav-tabs > li > a {
  font-size: 13px;
}

.page-title {
  color: $primaryClr;
  border-left: 2px solid #f89325;
  font-size: 24px;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
}

.search {
  margin-right: 1em;
  float: left;
  padding: 6px 12px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 2;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.btn {
  border-radius: 5px;
  transition: all 0.1s ease;
  padding: 0.344rem 1rem;
  font-size: 0.9rem;
}

.card {
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.input-group {
  margin-bottom: 5px;
}

.form-group label {
  font-size: 13px;
}

.tab-content {
  padding: 10px 22px;
}

.form-control {
  font-size: 12px;

  &:focus {
    outline: none !important;
  }
}

.btn-group-toggle > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;

    input {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      border-radius: 0;
    }
  }
}

.mr-4 {
  margin-right: 1.4rem !important;
}

.formTop {
  margin-top: 2rem;
}

h3 {
  font-size: 20px;
}

.modal-header {
  border-bottom: 1px solid #bababa;
}

select option {
  color: black;
  background-color: rgb(255, 255, 255);
  height: 20rem;
}

select option:hover {
  color: black;
  background-color: rgb(255, 255, 255);
}

.clearfix:after {
  display: block;
  clear: both;
  content: '';
}

label.custom-checkbox {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;

  & > input {
    height: 0;
    z-index: -100 !important;
    opacity: 0;
    position: absolute;

    &:checked {
      & + span {
        &:before {
          content: '\f00c';
          font-weight: 300;
        }
      }
    }

    &:disabled {
      & + span {
        color: darkslategray;
        cursor: not-allowed;

        &:before {
          border-color: darkslategray !important;
          cursor: not-allowed;
        }
      }
    }

    &:focus {
      & + span {
        &:before {
          border-color: #58a1d6;
        }
      }
    }
  }

  & > span {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    padding-left: 22px;
    width: 100%;

    &:before {
      cursor: pointer;
      font-family: fontAwesome;
      font-weight: 300;
      font-size: 12px;
      color: #454545;
      content: '\a0';
      background-color: transparent;
      border: 1px solid rgba(54, 52, 52, 0.6);
      border-radius: 0;
      display: inline-block;
      text-align: center;
      height: 16px;
      line-height: 14px;
      min-width: 16px;
      margin-right: 6px;
      position: relative;
      top: 0;
      margin-left: -22px;
      // float: left;
    }

    &:hover {
      &:before {
        border-color: #58a1d6;
      }
    }
  }
}

.btn.disabled,
.btn:disabled,
button:disabled,
input:disabled {
  &:hover {
    cursor: not-allowed !important;
  }
}

input[type='button'],
button,
.btn,
.btn-primary,
.btn-success,
.btn-default .mat-raised-button.mat-primary {
  &:focus {
    outline: none !important;
  }
}

.mat-raised-button.mat-primary {
  height: 30px;
  width: auto;
  background-color: white;
  border-radius: 6px;
  color: $primaryClr;
  border: 1px solid $primaryClr;
}

.mat-raised-button.mat-primary:hover {
  background-color: $primaryClr;
  color: white;
  padding-right: 10px;
}

.mat-raised-button.mat-primary span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.mat-raised-button.mat-primary > span > span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}

.mat-raised-button.mat-primary:hover > span > span {
  padding-right: 25px;
}

.mat-raised-button.mat-primary:hover span:after {
  opacity: 1;
  right: 0;
}

::ng-deep .mat-tab-label {
  height: 35px !important;
  padding: 0 0 !important;
  min-width: 120px !important;
}

::ng-deep .mat-tab-label.mat-tab-label-active {
  color: $primaryClr !important;
}

::ng-deep .mat-ink-bar {
  background-color: $primaryClr !important;
}

.card-title {
  color: $primaryClr;
  font-size: 13px;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  margin-top: -12px;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
  height: 2.5em;
}

.ag-theme-material .ag-icon-checkbox-checked {
  color: $primaryClr !important;
}

.ag-theme-material .ag-icon-checkbox-unchecked {
  color: #e0e0e0 !important;
}

::ng-deep .mat-simple-snackbar-action {
  color: black;
}

::ng-deep .success-snackbar {
  background-color: rgb(217, 233, 217);
  color: rgb(9, 110, 9);
  border: 1px solid rgb(9, 110, 9);
  border-radius: 3px;
}

::ng-deep .error-snackbar {
  background-color: rgb(235, 220, 220);
  color: rgb(170, 15, 15);
  border: 1px solid rgb(170, 15, 15);
  border-radius: 3px;
}

::ng-deep .info-snackbar {
  background-color: $sideBarCol;
  color: $primaryClr;
  border: 1px solid $primaryClr;
  border-radius: 3px;
}

.icon-button:hover {
  color: $primaryClr;
  text-decoration: none;
  border: 1px solid $primaryClr;
  border-radius: 15px;
}

.icon-button i {
  margin-left: 3px;
}

.icon-action-button {
  color: $primaryClr;
  text-decoration: none;
  background-color: $secondryClr;
  border-radius: 5px;
  padding: 1px;
  margin-left: 0.4em;
}

.icon-action-button:hover {
  color: white;
  text-decoration: none;
  border: 1px solid $primaryClr;
  border-radius: 15px;
  background-color: white;
}

// body row center position
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value {
  display: flex !important;
  align-items: center !important;
}

/* .ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover,
.ag-theme-material .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing,
.ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover,
.ag-theme-material .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,
.ag-theme-material .ag-header-group-cell:first-of-type:hover,
.ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing,
.ag-theme-material .ag-header-cell:first-of-type:hover,
.ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing {
    background-color: $primaryClr;
    color: $secondryClr;
} */

.ag-theme-material .ag-header-cell {
  color: black;
  background-color: #e1e0e07a;
  font-size: 13px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  align-items: initial !important;
}

.ag-theme-material .ag-floating-filter-full-body {
  height: 100%;
  width: 100%;
}

.ag-theme-material .ag-floating-filter-full-body input {
  color: $secondryClr;
  border-bottom: 2px solid $secondryClr;
  padding-bottom: 0px !important;
}

.ag-theme-material .ag-floating-filter-full-body input:focus {
  border-bottom: 2px solid $sideBarCol;
}

.ag-theme-material .ag-cell {
  line-height: normal !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-theme-material .ag-input-wrapper input:not([type='date | text']) {
  color: $primaryClr;
  // border: 2px solid #e2e2e2;
  // border-radius: 5px;
  background-color: white !important;
  padding-top: 0 !important;
  height: 30px !important;
}

.ag-theme-material .ag-input-wrapper input:not([type='date | text']):focus {
  border-bottom-color: $primaryClr;
}

.ag-root-wrapper-body.ag-layout-normal {
  border: 1px solid antiquewhite;
}

.ag-theme-material {
  .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover,
  .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,
  .ag-header-group-cell:first-of-type:hover,
  .ag-header-group-cell:first-of-type.ag-column-resizing,
  .ag-header-cell:first-of-type:hover,
  .ag-header-cell:first-of-type.ag-column-resizing {
    background-color: #e1e0e07a !important;
    color: black;
  }
}

// .ag-theme-material .ag-header-row:first-child .ag-header-cell {
//   // padding: 0 !important;
// }

.ag-theme-material .ag-header-cell-resize {
  width: 10px !important;
}

.ag-theme-material .ag-header-cell-resize::after {
  border-right: 1px solid $primaryClr;
  content: ' ';
  height: 15px;
  margin-top: 10px;
  position: absolute;
  text-indent: -2000px;
  top: 0px;
  left: 0px;
}

.ag-theme-material .ag-row-selected {
  background-color: $sideBarCol;
}

::ng-deep .mat-checkbox .mat-checkbox-frame {
  border: 1.5px solid #e0e0e0;
}

::ng-deep .mat-checkbox-inner-container :checked {
  background-color: $primaryClr;
}

::ng-deep .mat-chip.mat-standard-chip {
  background-color: $sideBarCol !important;
  color: $primaryClr !important;
  border: 0.5px solid $primaryClr !important;
  border-radius: 5px !important;
  min-height: 25px !important;
}

::ng-deep .mat-card-content {
  font-size: 13px;
}

::ng-deep .mat-option.mat-active {
  background: $sideBarCol !important;
  color: $primaryClr !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled),
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: $sideBarCol !important;
  color: $primaryClr !important;
}

::ng-deep .mat-menu-item:hover:not([disabled]) {
  background: $sideBarCol;
  color: $primaryClr !important;
}

::ng-deep .mat-menu-item .mat-menu-item-active {
  background: $primaryClr !important;
  color: $sideBarCol !important;
}

::ng-deep .mat-pseudo-checkbox-checked {
  background-color: $primaryClr !important;
}

.modal-open .modal {
  margin-top: 2rem !important;
}

.modal-header {
  border-bottom: 1.5px solid #e0e0e0 !important;
}

.modal-title {
  color: lightslategray;
}

.modal-body {
  text-align: left !important;
  margin: auto;
  border: none !important;
}

.modal-content {
  display: inline-block !important;
  border-radius: unset !important;
  border: unset !important;
}

.modal-footer {
  margin: auto;
  border: none !important;
}

.modal-footer .close {
  margin-left: 5em;
}

.modal-footer mat-button {
  background-color: white;
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 5px 20px 5px 20px;
}

.modal-footer mat-button:hover {
  background-color: lightslategray;
  color: white;
}

mat-card {
  margin-bottom: 10px !important;
}

[hidden] {
  display: none;
}

// map component
.topBoxes {
  border-color: rgba(0, 0, 0, 0.6) !important;
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: black !important;
    background-color: transparent !important;
    border-bottom: 1px solid #4a615c !important;
  }

  /* .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #28758e #28758e #dee2e6 !important; // 1.top 2.left&right 3.bottom
  } */

  // ngb-tab headers
  .nav.nav-tabs > li > a {
    color: black;
    padding: 0.2rem 0.5rem !important;
  }

  .nav-tabs {
    padding-left: 0.6rem;
  }

  .tab-content {
    padding: 10px 0 0 0;
  }

  .progress-bar-striped {
    font-size: x-small;
    color: black;
    font-weight: 500;
  }

  .statusDiv,
  .utilizationDiv {
    .listItem {
      color: gray;
      font-size: smaller;

      .row {
        margin-top: 0.5rem;
      }
    }

    .list-group {
      color: gray;
      font-size: smaller;
    }

    .list-group-item {
      border: none;
      background: transparent;
      font-size: inherit;
      padding: 0rem 0rem 0.5rem;
    }

    li.list-group-item.active {
      background: #c99b9b40;
      color: black;
      border-left: 2px solid #00dcff;
      padding: 2px 10px 2px 5px;
      margin-bottom: 5px;
      margin-top: -2px;
      font-weight: 700;
      width: max-content;
    }
  }
}

/* .mapboxDiv {
  .list-group-item {
    border: none;
    // margin-top: 2px;
    font-size: 0.68rem;
  }

  .mapboxgl-popup-content {
    padding: 8px 10px 8px 6px;
  }

  // for popup on map
  .mapboxgl-popup {
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    width: initial !important;
    // width: -webkit-fill-available !important;
  }

  // pop-up close button
  .mapboxgl-popup-close-button {
    font-weight: 600;
    color: darkgreen;
    right: -3px;
    top: -3px;
  }

  .popupValue {
    font-size: x-small;
    margin-left: 1.2rem;
    padding-top: 2px;
  }
} */

/* .activatedAsset {
  width: 22px !important;
  height: 22px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  cursor: pointer;
  background-image: url(~/assets/images/activated-geo-locator.png) !important;
} */

// map component geo - for markers
#marker {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: aliceblue;
  background-origin: content-box;
  padding: 0.3rem;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.onBorder {
  border: 6px solid #17c217;
}

.offBorder {
  border: 6px solid red;
}

.downBorder {
  border: 6px solid $tertiaryClr;
}

.sensorDownBorder {
  border: 6px solid gray;
}

.loaderAssetClass,
.excavatorAssetClass,
.generatorAssetClass,
.impactCrusherAssetClass,
.jawCrusherAssetClass {
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
}

.acActiveMapPopup {
  border: 0.6rem solid green;
}

.acUtilizeMapPopup {
  border: 0.6rem solid orange;
}

.acIdleMapPopup {
  border: 0.6rem solid blue;
}

.acInActiveMapPopup {
  border: 0.6rem solid red;
}

.executiveDashboardMap {
  margin-top: -0.6rem !important;
  margin-left: -0.5rem !important;

  .btn:focus,
  button.mapboxgl-ctrl-zoom-in:focus,
  button.mapboxgl-ctrl-zoom-out:focus {
    outline: none;
    box-shadow: none;
  }

  .btn:hover {
    background: transparent;
  }

  // in all terms - aero
  .mapboxgl-popup-tip {
    border: 8px solid transparent;
  }

  // aero colors on different position

  $mapPopupTipColor: #2a5c74;

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: $mapPopupTipColor;
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: $mapPopupTipColor;
  }

  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: $mapPopupTipColor;
  }

  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: $mapPopupTipColor;
  }

  #map {
    width: 100% !important;
    height: calc(100vh - 58px) !important;
  }

  .cursor-default {
    cursor: default;
  }

  .mapboxgl-canvas-container,
  canvas.mapboxgl-canvas {
    width: 100% !important;
    height: calc(100vh - 58px) !important;
  }

  .mapboxgl-ctrl-top-right {
    right: 0rem;
  }

  // pop-up background color
  .mapboxgl-popup-content {
    background: #cccaca;
    padding: 0;
  }

  .card {
    margin-bottom: 0;
    border: none;
    width: 14rem;
  }

  .card-header {
    padding: 5px 10px 5px;
    border-bottom: none;
    color: white;

    .spanEllipses {
      display: inline-block;
      width: inherit;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  .card-body {
    padding: 0.5rem;
  }

  // zoom in, out
  .mapboxgl-ctrl-group {
    background: #f0f8ff52;
  }

  .popupMapPlaceIcon,
  .popupMapSpeedIcon,
  .popupMapClockIcon {
    height: 1.4rem;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

// ~~~~~~~~~~~~~~~~~~~~ GOOGLE MAP STYLES ~~~~~~~~~~~~~~~~~~~~
.executiveGoogleMap {
  margin-top: -0.6rem !important;
  margin-left: -0.5rem !important;

  // Toggle full screen
  button.gm-control-active.gm-fullscreen-control {
    border: none !important;
    width: 28px !important;
    height: 28px !important;
    background-color: #fffbfbf7 !important;
    right: 4px !important;
    img {
      height: 12px !important;
      width: 12px !important;
    }
  }

  // each zoom control
  button.gm-control-active {
    // border: 1px solid red !important;
    width: 32px !important;
    height: 30px !important;
    margin-left: 2px !important;
    background-color: #fffbfbf7 !important;
    img {
      height: 12px !important;
      width: 12px !important;
    }
  }

  // break line b/w zoom controls - hiding logic
  .gmnoprint {
    margin-top: 10px;
    div {
      background-color: transparent !important;
    }
  }

  .customInfoWindow {
    span {
      display: block !important;
      color: black !important;
      margin: 0px !important;
      font-size: small;
      font-weight: 600;
    }
    padding: 3px 0 0 3px !important;
  }
  .gm-ui-hover-effect {
    display: none !important;
  }
  .gm-style-iw {
    background: #f0f8ff !important;
    padding: 0 !important;
  }
  .gm-style-iw-d {
    background: transparent !important;
    padding: 0 !important;
  }

  // down diamond symbol
  .gm-style .gm-style-iw-t::after {
    display: none !important;
  }

  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
    background: transparent !important;
  }

  // popup left & top
  .gm-style .gm-style-iw-c {
    transform: translate(-45%, -85%) !important;
  }
}

// ~~~~~~~~~~~~~~~~~~~~ GOOGLE MAP STYLES ~~~~~~~~~~~~~~~~~~~~

button.mat-button.mat-primary {
  background: white;
  color: black;
  border: 1px solid #e0e0e0;
  height: 2rem;
  border-radius: 0;
}

span.mat-button-wrapper {
  display: flex;
  align-items: center;
  height: inherit;
}

button.mat-button.mat-primary:hover {
  background: black;
  color: white;
  border: 1px solid #fff;
}

// dashboard page
.progressChart {
  .mat-button-toggle-label-content {
    padding: 0 6px !important;
  }
}

.pointerCursor {
  cursor: pointer;
}

.globalNgbTab {
  .tab-content {
    padding: 0rem 0 0.5rem;
  }
  // .nav-tabs {
  //   //padding-left: 0.6rem;
  // }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #1a6a9f !important;
    background-color: aliceblue !important;
    // border-bottom: 1px solid #4a615c !important;
  }

  // ngb-tab headers
  .nav.nav-tabs > li > a {
    color: grey;
    padding: 0.3rem 0.8rem !important;
  }

  .tab-content {
    padding: 10px 0 0 0;
  }
}

.heatMapChart {
  $mat-card-border: 1.5px solid #e2e2e2;
  border-left: $mat-card-border;
  border-right: $mat-card-border;
  border-bottom: $mat-card-border;

  .apexcharts-legend {
    justify-content: flex-start !important;
    padding-left: 30rem !important;
  }
  .apexcharts-toolbar {
    display: none;
  }
}

// top alerts and cases charts
/* ::ng-deep ngx-charts-bar-vertical-stacked,
::ng-deep ngx-charts-bar-vertical {
    text {
        text-anchor: middle !important;
    }
} */

/* // heat-map Y axis lables
::ng-deep apx-chart {
    text {
        text-anchor: end !important;  // no-need
    }
} */

// dashboard cards

.mat-card.dashboardCard {
  box-shadow: none !important;
  border: 1.5px solid #e2e2e2;

  .mat-card-header {
    color: black;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background: #95c4f3;
    // border: 1.5px solid #e2e2e2;

    .mat-card-title {
      font-size: 13px;
      // font-weight: 600;
      margin-bottom: 0px;
      color: white;
      font-weight: 700;
    }

    .mat-card-header-text {
      margin: 6px 10px !important;
      width: 100%;
    }
  }

  mat-card-content.topBoxesMatCard.mat-card-content {
    min-height: 10.9rem !important;
  }

  mat-card-content.assetUtilizationBlock.mat-card-content {
    min-height: 30rem;
    display: flex;
    align-items: center;
  }

  /* .mat-card-content {
        $mat-card-border: 1.5px solid #468fd3;
        border-left: $mat-card-border;
        border-right: $mat-card-border;
        border-bottom: $mat-card-border;
    } */
}

.mat-card.breakdownBox {
  .mat-card-header {
    background: indianred;
    color: black;
  }
}

.mat-card.idleBox {
  .mat-card-header {
    background: #003152;
    color: black;
  }
}
.mat-card.case-status {
  .mat-card-header {
    // background: #4F97A3;
    background: #366280;
    color: black;
  }
}

mat-card.dashboardCard.topBoxes {
  min-height: 13rem;

  .topBoxesBorder {
    display: flex;
    align-items: center;
    min-height: 2.5rem;
  }
}
.mat-card.noOfTrip {
  .mat-card-header {
    background: #72a2c0;
  }
}
.mat-card.noOfCases {
  .mat-card-header {
    background: #00800080;
  }
}
.mat-card.fuel-mileage {
  .mat-card-header {
    background: #95c8d8;
  }
}

.mat-form-field--inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mat-form-field--inline .mat-form-field {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.mat-form-field--inline .mat-form-field:nth-child(n + 1) {
  margin-right: 10px;
}
mat-form-field {
  width: 100%;
}
::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: #0080ff !important;
}
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primaryClr !important;
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $primaryClr !important;
}
::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2a5c74 !important;
}
::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #2a5c74b0 !important;
}
.redirectRendererMenu {
  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  img {
    width: 0.9rem;
  }
  button.border-0 {
    background: none !important;
  }
}

.whatsAppDetails {
  .mat-card-header-text {
    margin: 0 !important;
  }
}

.mat-mini-fab-button {
  background-color: transparent !important;
  margin: 0 2px 0 2px !important;
  box-shadow: none !important;
  .fa {
    margin-left: 5px;
  }
}
.mat-mini-fab-button:hover {
  background-color: aliceblue !important; // #f89325;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border: 0.5px solid $tertiaryClr;
}

.matCard {
  margin-bottom: 10px;
}

table,
th,
td {
  border-collapse: collapse;
  padding: 0.3rem !important;
}
th,
td {
  padding: 5px;
}
th {
  font-weight: 500;
  color: lightslategray;
}
.fa {
  margin-left: 5px;
}
.input-field {
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 3px 5px 3px 5px;
}
::ng-deep .mat-hint-primary {
  color: royalblue !important;
  font-size: x-small;
  font-weight: 600;
}
::ng-deep .mat-hint-danger {
  color: red !important;
  font-size: x-small;
  font-weight: 600;
}
::ng-deep .mat-hint-success {
  color: green !important;
  font-size: x-small;
  font-weight: 600;
}
::ng-deep .ag-theme-material .ag-row .ag-cell {
  &.green-interval {
    background-color: #00ba00;
    text-align: center;
    color: white;
    border: 2px solid snow;
  }
  &.yellow-interval {
    background-color: #f8c400;
    text-align: center;
    color: white;
    border: 2px solid snow;
  }
  &.grey-interval {
    background-color: #e8e8e8;
    text-align: center;
    color: white;
    border: 2px solid snow;
  }
  &.red-interval {
    background-color: red;
    text-align: center;
    color: white;
    border: 2px solid snow;
  }
  &.work-hour-asset-interval {
    color: #6699cc;
    font-weight: 600;
  }
  &.present-interval-attendance {
    background-color: #90ee90;
    text-align: center !important;
    color: snow;
    font-weight: bold;
    border: 2px solid snow;
  }
  &.absent-interval-attendance {
    background-color: #e8e8e8;
    text-align: center;
    color: #e8e8e8;
    font-weight: bold;
    border: 2px solid snow;
  }
  &.employee-id-attendance {
    color: #6699cc;
    font-weight: 600;
  }
}

.normal-screen-interval-graph {
  ag-grid-angular {
    height: calc(100vh - 9rem) !important;
  }
}

.full-screen-interval-graph {
  ag-grid-angular {
    height: calc(100vh - 5rem) !important;
  }
}

ag-grid-angular {
  height: calc(100vh - 8rem) !important;
}

.heatMapFullScreen {
  padding: 1rem;
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fffffff7;
  animation: topToBottom 0.3s linear forwards;
}

/* New UI CSS */

.scrollspy {
  position: relative;
  height: calc(100vh - 5rem);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -0.3rem;

  .dashboard-card {
    margin-top: -0.6rem;
  }
  // .scroll-section {
  //   // margin-top: -5px !important;
  // }
}
.mat-label-card {
  width: 100%;
  box-shadow: none !important;
  // border: 2px solid #e0e0e0;
  margin-top: 0px;
  border-radius: 0 !important;

  &.nav-heading-card {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    align-items: center;
    margin: 9px 0 0 0 !important;
    height: 2.8rem;
    z-index: 1;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
  }
  &.nav-heading-card::-webkit-scrollbar {
    display: none;
  }
  &.non-dashboard-card {
    margin-top: 10px;
    margin-bottom: 0.2rem !important;
    height: calc(100vh - 4rem);
  }
}

.nav.nav-pills {
  flex-wrap: nowrap !important;
  font-size: 1em;
}

.nav-item > .nav-link {
  white-space: nowrap;
}

.nav-pills > .nav-item:not(:first-child) {
  & .nav-link {
    margin-left: 1.5rem;
  }
  &::after {
    content: '';
    height: 36%;
    width: 1px;
    position: absolute;
    top: 16px;
    background-color: lightslategray;
  }
}

.nav-pills .nav-item .nav-link {
  color: black;
  border-radius: 0 !important;
  margin-left: -0.8rem;
  margin-right: 1.5rem;
  font-size: inherit;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #007bff !important;
  //color: #000000 !important;
  background: #e0e0e0;
  font-weight: 500;
  //width: 160px;
}
.nav-link:focus,
.nav-link:hover {
  background: #e0e0e0;
}
.highchart-card {
  // height: 300px;
  width: 100%;
  display: inline-block;
  margin-top: -30px;
}
.chart-card-title {
  border-bottom: 1.5px solid #e0e0e0;
  //margin-left: -17px;
  //margin-right: -17px;
  padding-left: 20px;
  padding-bottom: 14px;
  text-align: center;
  color: #000000b5;
  font-size: larger;
  font-weight: 300;
  width: 100%;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: rgb(39, 110, 241);
  border-color: white;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgb(39, 110, 241);
  border-color: white;
}
